import React, { Fragment } from 'react';
import { MouseEventHandler } from 'react';
import { karteTrackingId } from '~/constants/trackingId';
import { FontSizeName } from '~/styles/font';
import LoadingSpinner from '~/assets/animation/loading-spinner.svg';
import * as styles from './index.styles';
import {
  ButtonHeight,
  ButtonDesign,
  ButtonType,
  ButtonDisplay,
  ButtonCustomCss,
} from './index.type';

export type Props = {
  fontSize: FontSizeName;
  mobileFontSize?: FontSizeName;
  buttonHeight: ButtonHeight;
  mobileButtonHeight?: ButtonHeight;
  design?: ButtonDesign;
  children: React.ReactNode;
  display?: ButtonDisplay;
  type?: ButtonType;
  iconSrc?: string;
  iconAlt?: string;
  onClick?: MouseEventHandler;
  tabIndex?: number;
  disable?: boolean;
  round?: boolean;
  testId?: string;
  loading?: boolean;
  id?: string;
  minWidth?: number;
  customCss?: ButtonCustomCss;
  autoFocus?: boolean;
};

export const MESSAGE = {
  LOADING: 'ロード中',
} as const;

export const Button: React.FC<Props> = (props: Props) => {
  const content = (
    <Fragment>
      {props.loading ? (
        <img src={LoadingSpinner} alt={MESSAGE.LOADING} css={styles.loadingIcon} />
      ) : (
        <span css={styles.content.container}>
          {props.iconSrc && (
            <div
              css={[
                styles.content.iconContainer({
                  height: props.buttonHeight,
                  mobileHeight: props.mobileButtonHeight,
                  design: props.design || 'default',
                }),
                props.customCss?.content,
              ]}
            >
              <img src={props.iconSrc} css={styles.content.icon} alt={props.iconAlt} />
            </div>
          )}
          <span
            css={[
              styles.content.text({
                height: props.buttonHeight,
                mobileHeight: props.mobileButtonHeight,
                design: props.design || 'default',
              }),
              props.customCss?.content,
            ]}
          >
            {props.children}
          </span>
        </span>
      )}
    </Fragment>
  );

  const commonProps = {
    'data-testid': props.testId,
    tabIndex: props.tabIndex,
    id: props.id,
    css: [
      styles.design[props.design || 'default'],
      styles.base(props.buttonHeight, props.fontSize, {
        minWidth: props.minWidth,
        mobileButtonHeight: props.mobileButtonHeight,
        mobileFontSize: props.mobileFontSize,
      }),
      props.disable ? styles.disable : null,
      styles.displayType(props.display || 'inline'),
      props.round ? styles.round : null,
      props.loading ? styles.loading : null,
      styles.padding(props.fontSize),
      props.customCss?.container,
    ],
  };

  return (
    <button
      {...commonProps}
      type={props.type || 'button'}
      onClick={props.onClick}
      disabled={props.disable || props.loading}
      autoFocus={props.autoFocus}
      data-tracking-id={karteTrackingId.BUTTON}
    >
      {content}
    </button>
  );
};
