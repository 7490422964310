import React from 'react';
import { NextRouter } from 'next/router';
// eslint-disable-next-line strict-dependencies/strict-dependencies
import { getClientContextProvider } from '~/categories/client/context';

export const FeatureProvider: React.FC<{ children: React.ReactNode; router: NextRouter }> = ({
  children,
  router,
}) => {
  const providers: React.FC<{ children: React.ReactNode }>[] = [];

  const ClientContextProvider = getClientContextProvider(router);
  if (ClientContextProvider !== null) {
    providers.push(ClientContextProvider);
  }
  // NOTE: clientカテゴリ以外のカテゴリも必要に応じてここに追加する

  if (!providers.length) return <>{children}</>;

  const Provider = providers.reduce((Acc, Curr) => {
    return ({ children }) => (
      <Acc>
        <Curr>{children}</Curr>
      </Acc>
    );
  });
  return <Provider>{children}</Provider>;
};
