import React from 'react';
import { Button } from '~/components/buttons/Button';
import { ButtonType, ButtonDesign } from '~/components/buttons/Button/index.type';
import * as styles from './index.styles';

type Section = {
  title?: React.ReactNode;
  children: React.ReactNode;
  key?: string;
};

type ApplyButton = {
  disable?: boolean;
  message: string;
  loading?: boolean;
  design?: ButtonDesign;
  testId?: string;
} & (
  | { type: 'submit' }
  | {
      type?: Exclude<ButtonType, 'submit'>;
      onClick: () => void;
    }
);

type CancelButton = {
  message: string;
  design?: ButtonDesign;
  testId?: string;
  onClick: () => void;
};

export type Props = {
  title?: React.ReactNode;
  sections: (Section | null)[];
  cancel?: CancelButton;
  apply?: ApplyButton;
  option?: {
    hideBorder?: boolean;
  };
};

const isSection = (section: Section | null): section is Section => section !== null;

export const ModalContentsContainer: React.FC<Props> & {
  JudgmentButtons: React.FC<{ cancel?: CancelButton; apply?: ApplyButton }>;
} = (props: Props) => (
  <div css={styles.container}>
    {props.title && <h1 css={styles.title}>{props.title}</h1>}

    {props.sections.filter<Section>(isSection).map((section, idx) => (
      <section
        key={section.key || idx}
        css={styles.section.container({
          hideBorder: !!props.option?.hideBorder,
        })}
      >
        {section.title && <h2 css={styles.section.title}>{section.title}</h2>}
        <div>{section.children}</div>
      </section>
    ))}
    {(props.apply || props.cancel) && (
      <div css={styles.buttons.container}>
        <JudgmentButtons apply={props.apply} cancel={props.cancel} />
      </div>
    )}
  </div>
);

const JudgmentButtons: React.FC<{ cancel?: CancelButton; apply?: ApplyButton }> = (props) => (
  <div css={styles.buttons.wrapper}>
    <div>
      {props.apply && (
        <Button
          type={props.apply.type}
          fontSize="s"
          buttonHeight={40}
          design={props.apply.design || 'primary'}
          display="block"
          disable={props.apply.disable}
          loading={props.apply.loading}
          onClick={'onClick' in props.apply ? props.apply.onClick : undefined}
          testId={props.apply.testId}
        >
          {props.apply.message}
        </Button>
      )}
    </div>
    <div
      css={styles.buttons.cancel({
        hasBothButtons: props.cancel !== undefined && props.apply !== undefined,
      })}
    >
      {props.cancel && (
        <Button
          fontSize="s"
          buttonHeight={40}
          design={props.cancel.design}
          display="block"
          testId={props.cancel.testId}
          onClick={props.cancel.onClick}
        >
          {props.cancel.message}
        </Button>
      )}
    </div>
  </div>
);

ModalContentsContainer.JudgmentButtons = JudgmentButtons;
