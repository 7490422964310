import { css } from '@emotion/react';
import { componentTokens } from './designToken';

export const linkStyle = css`
  text-decoration: underline;
  text-decoration-style: solid;
  text-decoration-color: currentcolor;
  cursor: pointer;
  color: ${componentTokens.text.link.default};

  &:hover {
    color: ${componentTokens.text.link.hover};
  }
`;
