const serviceName = 'timee-app';
const version = 'v1.0';

export const localStorageKeys = {
  searchClients: (companyId: number) => `${serviceName}/${version}/searchClients/${companyId}`,
  flashMessages: {
    message: `${serviceName}/${version}/flashMessages`,
    reloadFlag: `${serviceName}/${version}/flashMessages/reload`,
  },
  showNoteAfterBulkCreateOfferings: `${serviceName}/${version}/showNoteAfterBulkCreateOfferings`,
  offeringViewType: (clientId: number) => `${serviceName}/${version}/offeringViewType/${clientId}`,
  development: {
    featureFlags: `${serviceName}/${version}/featureFlags`,
  },
  minifySidebar: `${serviceName}/${version}/minifySidebar`,
  admin: {
    redirectionPath: `${serviceName}/${version}/admin/redirectionPath`,
  },
  searchAccessibleClients: `${serviceName}/${version}/searchAccessibleClients`,
} as const;
