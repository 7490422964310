import { css, SerializedStyles } from '@emotion/react';
import { colors } from '~/styles/colors';
import { componentTokens } from '~/styles/designToken';
import { FontSizeName } from '~/styles/font';
import { layout } from '~/styles/layout';
import { mediaQueries } from '~/styles/mediaQueries';
import { opacityStyle } from '~/styles/opacityStyle';
import { textCss } from '~/styles/textCss';
import { ButtonHeight, ButtonDesign, ButtonDisplay } from './index.type';

const textStyle = (fontSize: FontSizeName) => {
  const weight =
    fontSize === 'm' || fontSize === 'l' || fontSize === 'xl' || fontSize === 'xxl'
      ? 'bold'
      : 'regular';
  return textCss({ size: fontSize, weight });
};

const ButtonBorderSize = 2;

const innerHeight = (buttonHeight: number, design: ButtonDesign) =>
  buttonHeight - (design !== 'primary' ? 2 * ButtonBorderSize : 0);

export const disable = css`
  cursor: not-allowed;
`;

export const loading = css`
  cursor: wait;
`;

export const content = {
  container: (opt: { minWidth?: number }) => css`
    ${opt.minWidth ? `min-width: ${opt.minWidth}px;` : ''}

    display: flex;
    justify-content: center;
  `,
  text: (opt: { height: number; mobileHeight?: number; design: ButtonDesign }) => css`
    display: inline-block;
    height: ${innerHeight(opt.height, opt.design)}px;
    line-height: ${innerHeight(opt.height, opt.design)}px;
    vertical-align: top;
    overflow: clip;

    ${mediaQueries.mobile} {
      ${opt.mobileHeight && `height: ${innerHeight(opt.mobileHeight, opt.design)}px;`}
      ${opt.mobileHeight && `line-height: ${innerHeight(opt.mobileHeight, opt.design)}px;`}
    }
  `,
  iconContainer: (opt: { height: number; mobileHeight?: number; design: ButtonDesign }) => css`
    height: ${innerHeight(opt.height, opt.design)}px;
    position: relative;
    display: inline-block;
    margin-right: ${layout.baseSize.margin}px;

    ${mediaQueries.mobile} {
      ${opt.mobileHeight && `height: ${innerHeight(opt.mobileHeight, opt.design)}px;`}
    }
  `,
  icon: css`
    position: relative;
    height: 70%;
    transform: translateY(-50%);
    top: 50%;
  `,
};

export const base = (
  buttonHeight: ButtonHeight,
  fontSize: FontSizeName,
  opt: { minWidth?: number; mobileButtonHeight?: ButtonHeight; mobileFontSize?: FontSizeName },
) => css`
  ${textStyle(fontSize)}

  ${opt.minWidth ? `min-width: ${opt.minWidth}px;` : ''}

  overflow: hidden;
  height: ${buttonHeight}px;
  border-radius: ${layout.baseSize.borderRadius}px;
  cursor: pointer;

  ${mediaQueries.mobile} {
    ${opt.mobileButtonHeight ? `height: ${opt.mobileButtonHeight}px;` : ''}
    ${opt.mobileFontSize ? textStyle(opt.mobileFontSize) : ''}
  }
`;

export const padding = (fontSize: FontSizeName, mobileFontSize?: FontSizeName) => {
  const paddingSize: { [key in FontSizeName]: number } = {
    xxl: 32,
    xl: 28,
    l: 24,
    m: 18,
    s: 14,
    xs: 12,
    xxs: 12,
    xxxs: 10,
    caption: 10,
  };
  return css`
    padding: 0 ${paddingSize[fontSize]}px;

    ${mediaQueries.mobile} {
      ${mobileFontSize && `padding: 0 ${paddingSize[mobileFontSize]}px;`}
    }
  `;
};

export const innerContainer = css`
  display: flex;
  align-items: center;
`;

export const design: { [key in ButtonDesign]: SerializedStyles } = {
  primary: css`
    background-color: ${componentTokens.background.button.primary.default};
    color: ${componentTokens.textIconSet.inverse};
    border: none;

    &:hover {
      background-color: ${componentTokens.background.button.primary.hover};
    }

    &:disabled {
      background-color: ${componentTokens.background.button.disabled};
    }
  `,
  secondary: css`
    background-color: ${colors.white};
    color: ${colors.accent};
    border: ${ButtonBorderSize}px solid ${colors.accent};

    &:hover {
      background-color: ${colors.accent};
      color: ${colors.white};
      ${opacityStyle.translucent}
    }

    &:disabled {
      background-color: ${componentTokens.background.button.disabled};
      color: ${componentTokens.textIconSet.inverse};
      border: none;
    }
  `,
  danger: css`
    background-color: ${componentTokens.background.button.danger.default};
    color: ${componentTokens.textIconSet.inverse};
    border: none;

    &:hover {
      background-color: ${componentTokens.background.button.danger.hover};
    }

    &:disabled {
      background-color: ${componentTokens.background.button.disabled};
    }
  `,
  default: css`
    box-sizing: border-box;
    background-color: ${componentTokens.background.button.secondary.default};
    color: ${componentTokens.textIconSet.default};
    border: ${ButtonBorderSize}px solid ${componentTokens.border.button.secondary.default};

    &:hover {
      background-color: ${componentTokens.background.button.secondary.hover};
    }

    &:disabled {
      background-color: ${componentTokens.background.button.disabled};
      color: ${componentTokens.textIconSet.inverse};
      border: none;
    }
  `,
};

export const displayType = (display: ButtonDisplay) =>
  display === 'block'
    ? css`
        display: block;
        width: 100%;
      `
    : css`
        display: inline;
      `;

export const anchor = (buttonHeight: ButtonHeight) => css`
  text-decoration: none;
  box-sizing: border-box;
  display: inline-block;
  text-align: center;
  line-height: ${buttonHeight}px;
`;

export const round = css`
  border-radius: ${48}px;
`;

export const loadingIcon = css`
  padding: 4px 0;
  box-sizing: border-box;
  height: calc(100% - 8px);
`;
