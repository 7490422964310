import React from 'react';
import { NextRouter } from 'next/router';
// eslint-disable-next-line strict-dependencies/strict-dependencies
import { clientPathBuilder } from '~/categories/client/constants/clientPathBuilder';
import { OfferingFormValueContextProvider } from '~/categories/client/context/useDeliverOfferingFormValues';

export const getClientContextProvider = ({ asPath, query }: NextRouter) => {
  // clientカテゴリのガード
  if (!('clientId' in query)) return null;

  const providers: React.FC<{ children: React.ReactNode }>[] = [];

  const clientId = Number(query.clientId);
  const offerId = Number(query.offerId);
  const offeringId = Number(query.offeringId);

  if (
    asPath.startsWith(clientPathBuilder(clientId).offerings().new(offerId)) ||
    asPath.startsWith(clientPathBuilder(clientId).offerings().edit(offerId, offeringId)) ||
    asPath.startsWith(clientPathBuilder(clientId).offerings().confirm(offerId)) ||
    asPath.startsWith(clientPathBuilder(clientId).graduates().offerings().new(offerId)) ||
    asPath.startsWith(clientPathBuilder(clientId).graduates().offerings().confirm(offerId))
  ) {
    // 求人作成〜求人確認画面間のデータ共有Context
    providers.push(OfferingFormValueContextProvider);
  }

  if (!providers.length) return null;

  return providers.reduce((Acc, Curr) => {
    return ({ children }) => (
      <Acc>
        <Curr>{children}</Curr>
      </Acc>
    );
  });
};
