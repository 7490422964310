export type FontSizeName = 'xxl' | 'xl' | 'l' | 'm' | 's' | 'xs' | 'xxs' | 'xxxs' | 'caption';
export type FontWeightName = 'bold' | 'regular' | 'light';

const size: { [Key in FontSizeName]: number } = {
  xxl: 32,
  xl: 24,
  l: 20,
  m: 16,
  s: 14,
  xs: 12,
  xxs: 11,
  // TODO: SPでのカレンダーセルのフォントサイズ用だが整頓してよさそう
  xxxs: 10,
  caption: 9,
};

const weight: { [Key in FontWeightName]: number } = {
  bold: 600,
  regular: 400,
  light: 300,
};

export const font = {
  size,
  weight,
};
