import React, { Fragment } from 'react';
import css from '@emotion/css';
import { Message } from '~/types/message';

export const toastContent = (message: Message): JSX.Element => (
  <Fragment>
    {message.body.length === 1 ? (
      <Fragment>{message.body[0]}</Fragment>
    ) : (
      <ul
        css={css`
          list-style: disc;
          padding-left: 24px;
        `}
      >
        {message.body.map((item, idx) => (
          <li key={idx}>{item}</li>
        ))}
      </ul>
    )}
  </Fragment>
);
