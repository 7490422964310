import React, { useContext } from 'react';
import { Dispatch, SetStateAction, createContext, useState } from 'react';
import { Offering, OfferingInputValues } from '~/categories/client/models/types/offering';

export type OfferingFormValuesContextState = Offering & {
  inputFormValues?: OfferingInputValues;
} & { offeringId?: number };

export const OfferingFormValueContext = createContext<OfferingFormValuesContextState | null>(null);
const OfferingFormSetValueContext = createContext<
  Dispatch<SetStateAction<OfferingFormValuesContextState | null>>
>(() => undefined);

export const OfferingFormValueContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [offeringFormValues, setOfferingFormValues] =
    useState<OfferingFormValuesContextState | null>(null);

  return (
    <OfferingFormValueContext.Provider value={offeringFormValues}>
      <OfferingFormSetValueContext.Provider value={setOfferingFormValues}>
        {children}
      </OfferingFormSetValueContext.Provider>
    </OfferingFormValueContext.Provider>
  );
};

const useOfferingFormValues = () => useContext(OfferingFormValueContext);
const useOfferingFormSetValues = () => useContext(OfferingFormSetValueContext);

export const useDeliverOfferingFormValues = () => {
  const setValue = useOfferingFormSetValues();
  const set = (offeringFormInfo: OfferingFormValuesContextState | null) => {
    setValue(offeringFormInfo);
  };

  const value = useOfferingFormValues();
  const get: () => OfferingFormValuesContextState | null = () => value;
  const clear = () => set(null);

  return {
    set,
    get,
    clear,
  };
};
