import { css } from '@emotion/react';
import { boxShadowStyles } from './boxShadowStyles';
import { componentTokens } from './designToken';
import { linkStyle } from './linkStyle';
import { mediaQueries } from './mediaQueries';
import { textCss } from './textCss';

export const global = css`
  body,
  pre {
    font-family: 'Helvetica Neue', Helvetica, 'Segoe UI', 'Hiragino Sans', 'メイリオ',
      'Meiryo, Osaka', 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
    ${textCss({ size: 'm', weight: 'regular' })}
  }

  html,
  body,
  /* Next.js */
  #__next,
  /* Storybook */
  #root {
    height: 100%;
  }

  body {
    color: ${componentTokens.textIconSet.default};
  }

  a {
    ${linkStyle}
  }

  input:focus,
  textarea:focus {
    outline: none;

    ${boxShadowStyles.outerNormal};
  }

  /* NOTE: 印刷時にスクロールバーが表示されないようにする */
  ${mediaQueries.print} {
    body: {
      overflow-y: hidden;
    }
  }
`;
